import * as React from 'react'
import Article from '../components/article'
import Seo from '../components/seo'
import CtaArticle from '../components/ctaArticle'

const Trusts = () => {
  return (
    <>
      <Seo
        pageTitle={'Trusts'}
        pageDescription={`There are various types of Trusts that can help you safeguard your
      estate, especially when you want to have some sort of control after
      passing away. Trusts are controlled by your Trustees who would ensure
      your estate is followed by your personalised wishes you have
      stipulated within your Will.`}
      />

      <Article>
        <h1>Trusts</h1>

        <p>
          There are various types of Trusts that can help you safeguard your
          estate, especially when you want to have some sort of control after
          passing away. Trusts are controlled by your Trustees who would ensure
          your estate is followed by your personalised wishes you have
          stipulated within your Will.
        </p>

        <ul>
          <li>Bare Trusts</li>
          <li>Discretionary Trust</li>
          <li>Disabled persons Discretionary Trust</li>
        </ul>

        <p>
          Your consultant will discuss this with you further subject to your own
          personal circumstances.
        </p>
        <CtaArticle />
      </Article>
    </>
  )
}

export default Trusts
